/* eslint-disable consistent-return */
// 请注意，在 "vue-router": "4" 版本中，Vue推荐使用return而非next()。
// import axios from 'axios'
import { http } from '@/lib/http'
import { find, isEmpty } from 'lodash-es'
import { useCookies } from 'vue3-cookies'
import { projectRouterGuider } from './projectGuard'
import { useGlobalStore } from '@/stores/modules/global'
import { useEntityStore } from '@/stores/modules/entity'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { RouteLocationNormalized, RouteLocationNormalizedLoaded } from 'vue-router'

export default {
  init: async (to: RouteLocationNormalized) => {
    if (to.name === 'maintenance') return
    const globalStore = useGlobalStore()
    if (isEmpty(globalStore.languages) && to.name !== 'notFound') {
      await globalStore.globalConfigInit()
    }
  },
  authVerify: async (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded) => {
    if (to.name === 'maintenance') return true

    // 维护中注释
    // if (to.fullPath !== '/') {
    //   return { path: '/' }
    // }
    // return true
    // eslint-disable-next-line no-unreachable, no-unreachable
    const { cookies } = useCookies()
    const globalStore = useGlobalStore()
    const entityStore = useEntityStore()
    const userGlobalStore = useUserGlobalStore()
    const menuGlobalStore = useMenuGlobalStore()
    const token = cookies.get('elv-app-token')

    if (to.name === 'notFound') return
    if (!isEmpty(userGlobalStore.user)) {
      if (!userGlobalStore.user?.name && to.name !== 'login') {
        return { name: 'login', query: { state: 2 } }
      }
      if (globalStore.projectList.length && !menuGlobalStore.menuList.length) {
        await menuGlobalStore.menuListInit()
      }
    }

    if (
      entityStore.intervalTimer !== null &&
      (entityStore.entityDetail.entityId !== to.params?.entityId || to.meta?.slug !== from.meta?.slug)
    ) {
      http.cancelAllRequest()
    }

    if (to.path === '/') {
      const nextRouter = await projectRouterGuider(from, to, true)
      return nextRouter
    }

    if (token) {
      if (isEmpty(userGlobalStore.user)) {
        if (cookies.get('elv-app-version') === 'v2') {
          window.open(`${import.meta.env.VITE_APP_VERSION_URL}`, '_self')
          return false
        }
        const userInitResult = await userGlobalStore.userInit()
        menuGlobalStore.menuListInit()
        // 根据用户初始化结果 true正常走 false进前台首页
        if (!userInitResult) {
          return { name: 'landingPage' }
        }
      }
      if ((to.name === 'login' || to.name === 'signup') && !to.query?.token) {
        const nextRouter = await projectRouterGuider(from, to)
        return nextRouter
      }
    }
    const entityDetail = find(globalStore.entityListAll, { entityId: String(to.params?.entityId) })

    if (to.matched.some((record) => record.name === 'entity') && isEmpty(entityDetail)) {
      const nextRouter = await projectRouterGuider(from, to)
      return nextRouter
    }

    if (
      to.params?.entityId &&
      to.name === 'entity-accounts-total-position' &&
      to.params?.entityId === entityDetail?.entityId &&
      !entityDetail?.enableHourlyUpdate
    ) {
      return { name: 'entity-accounts-treasury', params: { entityId: to.params?.entityId } }
    }

    if (to.name === 'landing-apply' && to?.query?.projectId && token) {
      return { name: 'landing-activate', query: { projectId: to.query?.projectId } }
    }

    if (
      ['entity-settings', 'project-settings'].includes(to?.name as string) &&
      !find(globalStore.projectList, { projectId: String(to.params?.projectId) })
    ) {
      return { path: '/' }
    }

    if (token && to.name === 'basic-free-project') return { path: '/', query: to.redirectedFrom?.query }

    // 这个路由不需要验证
    if (!to.matched.some((record) => record.meta.requiresAuth)) return

    if (!token && to.name !== 'oauth-google') {
      if (to.name === 'landing-apply' && to.query?.token && to.query?.projectId) {
        cookies.set('elv-app-token', String(to.query?.token), '7d', '/', import.meta.env.VITE_APP_COOKIES_DOMAIN, true)
        return { name: 'login', query: { token: String(to.query?.token), projectId: String(to.query?.projectId) } }
      }
      return { name: 'login', query: to.redirectedFrom?.query }
      //   userGlobalStore.openLogin()
      //   return false
    }
  }
}

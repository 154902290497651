<template>
  <el-select
    v-model="model"
    v-bind="$attrs"
    popper-class="elv-base-extend-select-popper"
    class="elv-base-extend-select"
    @visible-change="onSelectPopperVisibleChange"
  >
    <template v-if="prefixText" #prefix>
      <span v-if="prefixText">{{ prefixText }}</span>
    </template>
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="transformI18n(item.label)"
      :value="item.value"
      :disabled="item.isDisabled"
    >
      <el-checkbox
        v-if="isMultiple"
        v-model="item.isCheck"
        style="pointer-events: none"
        :value="item.value"
      ></el-checkbox>
      <SvgIcon
        v-if="item.svgName"
        :name="item.svgName"
        :width="svgWidth"
        :height="svgHeight"
        style="margin-right: 4px"
      />
      <img v-if="item.icon" :src="item.icon" :alt="item.alt" />
      {{ transformI18n(item.label)
      }}<span v-if="isShowSuffixInfo && item.suffixName" class="suffix-name">{{ item.suffixName }}</span
      ><span v-if="item?.isDeleted" class="is-deleted">deleted</span></el-option
    >
    <template #label="{ label, value }">
      <div v-if="!isMultiple" class="elv-select-single__selection">
        <SvgIcon
          v-if="currentSvgName(value).value"
          :name="currentSvgName(value).value"
          :width="svgWidth"
          :height="svgHeight"
        />
        <img v-if="currentIcon(value).value" :src="currentIcon(value).value" :alt="label" /><span>{{ label }}</span>
      </div>
    </template>
    <template #tag>
      <div v-if="isMultiple" class="elv-select-selected-multiple-tags">
        <el-tag
          v-for="value in model?.length > 1 ? model?.slice(0, 2) : model"
          :key="value"
          :closable="props.closableTag"
          disable-transitions
          @close="onClosableSelectTag(value)"
        >
          <div class="elv-select-selected-multiple-tag__item">
            <img v-if="currentIcon(value).value && props.showTagIcon" :src="currentIcon(value).value" />
            <span>{{ transformI18n(getSelectItemName(value)) }}</span>
          </div></el-tag
        >
        <el-tooltip
          v-if="Array.isArray(model) && model?.length > 2"
          effect="light"
          placement="bottom"
          popper-class="elv-report-table-tips is-light elv-select-drawer-filter-tips-common"
          :show-after="500"
        >
          <el-tag :key="model?.[3]" type="info" disable-transitions>+{{ model.length - 2 }}</el-tag>
          <template #content>
            <el-tag
              v-for="selectTagItem in model?.slice(2)"
              :key="selectTagItem"
              :closable="props.closableTag"
              disable-transitions
              type="info"
              effect="light"
              @close="onClosableSelectTag(selectTagItem)"
              ><img v-if="currentIcon(selectTagItem).value" :src="currentIcon(selectTagItem).value" />
              <span>{{ transformI18n(getSelectItemName(selectTagItem)) }}</span></el-tag
            >
          </template>
        </el-tooltip>
      </div>
    </template>
    <template #empty>
      <slot name="emptyContent"></slot>
    </template>
    <template #footer>
      <div v-if="!!collapseList.length" class="elv-base-extend-select-footer-collapse-content">
        <el-collapse v-model="currentCheckCollapseGroup"
          ><el-collapse-item
            v-for="(collapseItem, index) in collapseList"
            :key="index"
            :title="collapseItem.title"
            :name="collapseItem.title"
          >
            <el-option
              v-for="collapseDetailItem in collapseItem.list"
              :key="collapseDetailItem.value"
              :label="transformI18n(collapseDetailItem.label)"
              :value="collapseDetailItem.value"
            >
              <el-checkbox
                v-if="isMultiple"
                v-model="collapseDetailItem.isCheck"
                style="pointer-events: none"
                :value="collapseDetailItem.value"
              ></el-checkbox>
              <SvgIcon
                v-if="collapseDetailItem.svgName"
                :name="collapseDetailItem.svgName"
                :width="svgWidth"
                :height="svgHeight"
              /><img v-if="collapseDetailItem.icon" :src="collapseDetailItem.icon" :alt="collapseDetailItem.alt" />{{
                transformI18n(collapseDetailItem.label)
              }}
            </el-option>
          </el-collapse-item></el-collapse
        >
      </div>
      <slot name="footerCustomContent"></slot>
    </template>
  </el-select>
</template>
<script setup lang="ts">
import { find } from 'lodash-es'
import { transformI18n } from '@/i18n/index'
import { useComputedHook } from '@/hooks/useComputedHook'

type CollapseListType = {
  title: string
  list: ElvSelectOptionType[]
}

const props = defineProps({
  prefixText: {
    type: String,
    default: ''
  },
  options: {
    type: Array as () => ElvSelectOptionType[],
    default: () => []
  },
  width: {
    type: String,
    default: 'fit-content'
  },
  isRemoteIcon: {
    type: Boolean,
    default: false
  },
  isShowSuffixInfo: {
    type: Boolean,
    default: false
  },
  collapseList: {
    type: Array as () => CollapseListType[],
    default: () => []
  },
  closableTag: {
    type: Boolean,
    default: true
  },
  showTagIcon: {
    type: Boolean,
    default: true
  },
  svgWidth: {
    type: Number,
    default: 14
  },
  svgHeight: {
    type: Number,
    default: 14
  }
})

const attrs: any = useAttrs()
const model = defineModel<any>({ required: true })
const currentCheckCollapseGroup = ref<string[]>([])
const selectedInfoOptions = ref<ElvSelectOptionType[]>([])

/**
 * @description: 当前选中的icon
 * @param {string} value
 * @return {string}
 */
const currentIcon: any = useComputedHook((value: string) => {
  if (!props.options.length && !props.collapseList.length) return ''
  const mergedList = props.collapseList.flatMap((item) => item.list)
  const allListInfo = [...props.options, ...mergedList]
  return find(allListInfo, { value })?.icon || find(selectedInfoOptions.value, { value })?.icon || ''
})

/**
 * @description: 当前选中的svgName
 * @param {string} value
 * @return {string}
 */
const currentSvgName: any = useComputedHook((value: string) => {
  if (!props.options.length && !props.collapseList.length) return ''
  const mergedList = props.collapseList.flatMap((item) => item.list)
  const allListInfo = [...props.options, ...mergedList]
  return find(allListInfo, { value })?.svgName || find(selectedInfoOptions.value, { value })?.svgName || ''
})

/**
 * @description: 是否多选
 * @return {boolean}
 */
const isMultiple = computed(() => attrs?.multiple === '' || false)

/**
 * @description: 当前选中的name
 * @param {string} selectItem
 * @return {string}
 */
const getSelectItemName = (selectItem: string) => {
  const optionsItem = props.options.find((item) => item.value === selectItem)
  if (optionsItem) return optionsItem.label

  const collapseAllList = props.collapseList.flatMap((item) => item.list)
  const collapseItem = collapseAllList.find((item) => item.value === selectItem)
  if (collapseItem) return collapseItem.label

  const selectedInfoItem = selectedInfoOptions.value.find((item) => item.value === selectItem)
  return selectedInfoItem?.label || ''
}

/**
 * @description: 关闭tag
 * @param {string} selectItem
 */
const onClosableSelectTag = (selectItem: string) => {
  const findItemIndex = model.value.findIndex((item: any) => item === selectItem)
  if (findItemIndex !== -1) {
    model.value.splice(findItemIndex, 1)
  }
}

/**
 * @description: 选中option改变
 */
const onSelectOptionChange = () => {
  if (isMultiple.value) {
    const updateCheckStatus = (list: ElvSelectOptionType[]) => {
      list.forEach((item) => {
        item.isCheck = model.value.includes(item.value)
      })
    }
    updateCheckStatus(props.options)
    props.collapseList.forEach((itemArr) => updateCheckStatus(itemArr.list))

    const currentOptions = props.options.filter((item) => model.value.includes(item.value))
    selectedInfoOptions.value = [...new Set([...selectedInfoOptions.value, ...currentOptions])].filter((item) =>
      model.value.includes(item.value)
    )
  }
}

/**
 * @description: popper显示隐藏
 * @param {boolean} visible
 */
const onSelectPopperVisibleChange = (visible: boolean) => {
  if (isMultiple.value && visible) {
    currentCheckCollapseGroup.value = []
    props.collapseList.forEach((itemArr) => {
      itemArr.list.forEach((item) => {
        if (model.value.includes(item.value)) {
          currentCheckCollapseGroup.value.push(itemArr.title)
        }
      })
    })
  }
}

watch(
  () => model.value,
  () => {
    onSelectOptionChange()
  },
  { immediate: true }
)
</script>
<style lang="scss" scope>
.elv-base-extend-select {
  display: flex;
  align-items: center;

  &.el-select {
    width: fit-content;

    .el-select__wrapper {
      width: v-bind('width');

      .el-select__selection {
        width: fit-content;
        flex-wrap: nowrap;

        .elv-select-selected-multiple-tags {
          max-width: 100%;
          display: flex;
          align-items: center;
          gap: 6px;
        }

        &.is-near {
          margin-left: 0px;
        }

        .el-select__placeholder {
          display: flex;
          align-items: center;

          .elv-select-single__selection {
            display: flex;
            align-items: center;
            max-width: 100%;

            span {
              display: block;
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .suffix-name {
              color: #aaafb6;
              margin-left: 4px;
            }
          }

          img {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            display: block;
            margin-right: 4px;
          }
        }
      }

      .el-tag {
        border-radius: 3px;
        border: 1px solid #dde1e6;
        background: #edf0f3;
        display: flex;
        padding: 0px 4px 0px 8px;
        justify-content: center;
        align-items: center;
        gap: 2px;
        box-sizing: border-box;
        width: fit-content;

        .el-tag__content {
          display: flex;
          align-items: center;
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;

          .elv-select-selected-multiple-tag__item {
            display: flex;
            align-items: center;

            span {
              display: block;
              max-width: 50px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .el-select__tags-text {
            display: flex;
            align-items: center;
          }

          img {
            width: 14px;
            height: 14px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
          }
        }

        .el-icon.el-tag__close {
          width: 12px;
          height: 12px;
          color: #aaafb6;
          margin-left: 2px;

          &:hover {
            color: #fff;
            background-color: #909399;
          }
        }
      }

      .el-select__prefix {
        img {
          width: 16px;
          height: 16px;
          display: block;
        }
      }

      .el-select__suffix {
        .el-icon {
          margin-left: 4px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}

.elv-base-extend-select-popper {
  max-height: 400px;

  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__wrap {
    max-height: none;
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;

    img {
      width: 18px;
      height: 18px;
    }

    &:not(.is-disabled) &.hover {
      background: #f9fafb;
    }

    &.is-disabled {
      color: #a8abb2;
    }

    &.is-selected:after {
      display: none;
    }

    .suffix-name {
      margin-left: 4px;
      color: #aaafb6;
    }

    .is-deleted {
      display: flex;
      height: 19px;
      padding: 6px 8px;
      align-items: center;
      box-sizing: border-box;
      color: #fb212f;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Barlow';
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 14px;
      background: #edf0f3;
      margin-left: 8px;
    }
  }

  .el-select-dropdown__footer {
    border: none;
    padding: 0px;

    .el-collapse {
      border: none;

      .el-collapse-item__header {
        height: 32px;
        font-size: 12px;
        font-weight: 400;
        width: 100%;
        padding: 0px 8px;
        color: #838d95;
      }

      .el-select-dropdown__item {
        width: 100%;
        padding: 0 8px;
      }

      .el-collapse-item__content {
        padding-bottom: 0px;
      }
    }

    .elv-base-extend-select-footer-collapse-content {
      .el-collapse-item__wrap {
        border-bottom: 0px;
      }
    }
  }
}

.elv-select-drawer-filter-tips-common {
  display: flex;
  gap: 6px;
  max-width: 400px;
  max-height: 200px;
  flex-wrap: wrap;
  align-items: center;

  .el-tag__content {
    display: flex;
    align-items: center;
  }

  img {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }

  span {
    line-height: 14px;
  }
}
</style>

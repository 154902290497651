import { Directive } from 'vue'
import { throttle } from 'lodash-es'

interface ISkeletonOption {
  rows?: number
  radius?: number
  throttle?: number // 节流时间
}

export const tableSkeleton: Directive = {
  updated: (el: HTMLElement, binding) => {
    let { value } = binding
    if (typeof value !== 'object') {
      value = { loading: value }
    }
    const opts: ISkeletonOption = { rows: 20, radius: 5, throttle: 200, ...value }

    const throttledUpdate = throttle(() => {
      const tbody = el.querySelector('.el-table__body tbody')
      if (!tbody) return

      const emptyText = el.querySelector('.el-table__empty-block') as HTMLElement
      if (emptyText && value.loading) {
        emptyText.style.display = 'none'
      }

      const rows = tbody.querySelectorAll('tr.el-table__row:not(.elv-skeleton-row)') // 原有行
      const skeletonRows = tbody.querySelectorAll('tr.elv-skeleton-row') // 已存在的骨架屏行

      if (value.loading) {
        el.dataset.skeleton = '1'

        // 隐藏原有数据行
        rows.forEach((row) => {
          // eslint-disable-next-line no-param-reassign
          ;(row as HTMLElement).style.display = 'none'
        })

        // 清理旧的骨架屏行
        skeletonRows.forEach((row) => {
          tbody.removeChild(row)
        })

        // 显示骨架屏
        if (tbody) {
          const { rows: skeletonRowsCount = 5 } = opts
          for (let i = 0; i < skeletonRowsCount; i += 1) {
            const tr = document.createElement('tr')
            tr.className = 'elv-skeleton-row el-table__row'
            const cols = el.querySelectorAll('.el-table__header-wrapper th') // 表头列
            cols.forEach(() => {
              const td = document.createElement('td')
              td.className = 'cell'
              const skeleton = document.createElement('div')
              skeleton.className = 'el-skeleton is-animated'
              skeleton.innerHTML = `
                <div class="el-skeleton__item" style="line-height:16px;height:16px;margin:13px 0;border-radius:${opts.radius}px;"></div>
              `
              td.appendChild(skeleton)
              tr.appendChild(td)
            })
            tbody.appendChild(tr)
          }
        }
      } else if (!value.loading && el.dataset.skeleton === '1') {
        el.dataset.skeleton = '0'

        // 显示原有数据行
        rows.forEach((row) => {
          // eslint-disable-next-line no-param-reassign
          ;(row as HTMLElement).style.display = ''
        })

        // 移除骨架屏
        skeletonRows.forEach((row) => {
          tbody.removeChild(row)
        })

        const emptyElement = el.querySelector('.el-table__empty-block') as HTMLElement
        // 显示空数据提示
        if (emptyElement) {
          emptyElement.style.display = 'flex'
        }
      }
    }, opts.throttle || 200) // 动态使用 opts.throttle 参数

    // 执行节流更新
    throttledUpdate()
  }
}

import LedgerApi from '@/api/LedgerApi'
import EntityApi from '@/api/EntityApi'
import { CurrencyItemType } from '#/ReportsTypes'
import { useUserGlobalStore } from './global/user'
import TransactionsApi from '@/api/TransactionsApi'
import { JournalTypeItemType } from '#/TransactionsTypes'
import { ChartOfAccountItemType, ChartOfAccountListType } from '#/LedgerTypes'
import { find, keys, filter, forOwn, isEmpty, groupBy, forEach, camelCase } from 'lodash-es'
import { PlatformType, EntityDetailType, EntityMemberItemType, ExportTaskHistoryListType } from '#/EntityTypes'

export const useEntityStore = defineStore({
  id: 'entityStore',
  state: () => {
    const entityState: {
      loading: boolean
      platformList: PlatformType[]
      entityDetail: EntityDetailType
      currencyList: CurrencyItemType[]
      journalTypeList: JournalTypeItemType[]
      entityMemberList: EntityMemberItemType[]
      chartOfAccountList: ChartOfAccountItemType[]
      chartOfAccountOriginList: ChartOfAccountListType[]
      exportTaskHistoryList: ExportTaskHistoryListType[]
      intervalTimer: any
    } = {
      loading: false,
      platformList: [],
      currencyList: [],
      entityMemberList: [],
      entityDetail: {} as EntityDetailType,
      journalTypeList: [],
      chartOfAccountList: [],
      chartOfAccountOriginList: [],
      exportTaskHistoryList: [],
      intervalTimer: null
    }
    return entityState
  },

  actions: {
    /**
     *获取entity详情
     * @param {string} entityId 项目id
     */
    async fetchEntityDetail(entityId: string) {
      try {
        const response = await EntityApi.getEntityDetail(entityId)
        this.entityDetail = response.data
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取entity成员列表
     * @param {string} entityId 项目id
     */
    async fetchEntityMemberList(entityId: string) {
      try {
        const response = await EntityApi.getEntityMemberList(entityId)
        this.entityMemberList = response.data
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Currency列表
     * @param {string} entityId 项目id
     */
    async fetchCurrencyList(entityId: string) {
      try {
        const response = await EntityApi.getJournalCurrencyList(entityId)
        this.currencyList = response.data
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取journalType列表
     * @param {string} entityId 主体id
     */
    async fetchJournalTypeList(entityId: string) {
      const response = await TransactionsApi.getJournalTypeList(entityId)
      this.journalTypeList = response.data
      return response.data
    },
    /**
     * 获取科目列表
     * @param {string} entityId 项目id
     */
    async fetchChartOfAccountList(entityId: string) {
      const { data } = await LedgerApi.getChartOfAccountList(entityId)
      // 将树形结构转换为一维数组
      const coaList: any[] = []
      forEach(data, (item: any) => {
        const { list, ...rest } = item
        coaList.push(rest)
        forEach(list, (child: any) => {
          coaList.push(child)
        })
      })
      this.chartOfAccountList = coaList
      this.chartOfAccountOriginList = data
      return coaList
    },
    /**
     * 更新指定页面导出任务历史状态记录
     * @param {string} type 任务类型
     */
    async updateExportTaskHistory(entityId: string, type: string) {
      if (!type) return
      const { data } = await EntityApi.getExportTaskHistoryList(entityId, { type })
      const currentTypeHistory = find(this.exportTaskHistoryList, { type })
      if (!currentTypeHistory) {
        this.exportTaskHistoryList.push({ type, list: data || [] })
      } else {
        currentTypeHistory.list = data || []
      }
    },
    /**
     * entity初始化
     * @param {string} entityId 项目id
     */
    async entityInit(entityId: string) {
      this.loading = true
      const platformList = new Promise((resolve, reject) => {
        EntityApi.getPlatformList(entityId)
          .then((response) => {
            this.platformList = response.data
            return resolve(response.data)
          })
          .catch((e) => {
            return reject(e)
          })
      })

      const entityMemberList = new Promise((resolve, reject) => {
        EntityApi.getEntityMemberList(entityId)
          .then((response) => {
            this.entityMemberList = response.data
            return resolve(response.data)
          })
          .catch((e) => {
            this.entityMemberList = []
            return reject(e)
          })
      })

      try {
        const response = await Promise.all([
          this.fetchEntityDetail(entityId),
          this.fetchCurrencyList(entityId),
          platformList,
          entityMemberList,
          this.fetchJournalTypeList(entityId),
          this.fetchChartOfAccountList(entityId)
        ])
        this.loading = false
        return Promise.resolve(response)
      } catch (error) {
        this.loading = false
        return Promise.reject(error)
      }
    }
  },
  getters: {
    entityPermission: (state) => {
      return (user?: any) => {
        const userGlobalStore = useUserGlobalStore()
        let permission: any = {}
        let role = ''
        if (!isEmpty(user)) {
          permission = user.permission
          role = user.role
        } else {
          const entityMember = find(state.entityMemberList, {
            userId: userGlobalStore.user?.userId,
            email: userGlobalStore.user?.email
          })
          permission = entityMember?.permission
          role = entityMember?.role || ''
        }
        const groups = groupBy(keys(permission), (key: any) =>
          key.replace(
            /^(.*?)Erp|ViewDetail|ViewList|View|Update|BalanceAdd|BalanceVerify|BalanceExport|BalanceDelete|Generate|Export|Verify|Posting|Sync|Categorize|Execute|Create|Delete$/,
            '$1'
          )
        )
        const result: any = {}
        forOwn(groups, (values: any, key: any) => {
          const newKey = camelCase(key)
          const newObj: any = {}
          forEach(values, (value: any) => {
            newObj[camelCase(value.replace(key, ''))] = permission[value]
          })
          result[newKey] = newObj
        })
        result.role = role
        return result
      }
    },
    currencySign: (state) => {
      return state.entityDetail?.underlyingCurrency?.sign || state.entityDetail?.underlyingCurrency?.showSymbol || ''
    },
    chainList: (state): PlatformType[] => {
      return filter(state.platformList, (item) => item.type === 'CHAIN')
    },
    bankList: (state): PlatformType[] => {
      return filter(state.platformList, (item) => item.type === 'BANK')
    },
    exchangeList: (state): PlatformType[] => {
      return filter(state.platformList, (item) => item.type === 'EXCHANGE')
    },
    custodyList: (state): PlatformType[] => {
      return filter(state.platformList, (item) => item.type === 'CUSTODY')
    },
    paymentList: (state): PlatformType[] => {
      return filter(state.platformList, (item) => item.type === 'PAYMENT')
    }
  }
})

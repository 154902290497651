import { http } from '@/lib/http'
import { CurrencyItemType } from '#/ReportsTypes'
import { EntityDetailType, EntityMemberItemType, ExportTaskHistoryItemType } from '#/EntityTypes'

export default {
  /**
   * 删除Entity
   * @param {string} entityId 主体id
   */
  deleteProjectEntity: (entityId: string) => {
    return http.request('delete', `/entity/${entityId}`)
  },
  /**
   * 获取entity详情
   * @param {string} entityId 主体id
   */
  getEntityDetail: (entityId: string) => {
    return http.request<ResponseData<EntityDetailType>>('get', `/entity/${entityId}`)
  },
  /**
   * 编辑Entity
   * @param {Object} data
   * @property {string} data.name 主体名称
   * @property {string} data.logo 主体logo
   * @property {string} data.fairValueCostBasisMethodology 主体计价方法
   * @property {string} data.region 主体地区
   * @property {string} data.defaultCurrency 主体默认币种
   * @property {string} data.unit 主体单位
   * @property {string} data.creationDate 主体创建时间
   */
  editEntityDetail: (entityId: string, data: object) => {
    return http.request('put', `/entity/${entityId}`, { data })
  },
  /**
   * 获取entity成员列表
   * @param {string} entityId
   */
  getEntityMemberList: (entityId: string) => {
    return http.request<ResponseData<EntityMemberItemType[]>>('get', `/entity/${entityId}/member`)
  },
  /**
   * 获取currency列表
   * @param {string} entityId 主体id
   */
  getCurrencyList: (entityId: string) => {
    return http.request<ResponseData<CurrencyItemType[]>>('get', `/entity/${entityId}/currency`)
  },
  /**
   * 获取journal currency列表
   * @param {string} entityId 主体id
   */
  getJournalCurrencyList: (entityId: string) => {
    return http.request<ResponseData<CurrencyItemType[]>>('get', `/entity/${entityId}/journal/currency`)
  },
  /**
   * 获取platform列表
   * @param {string} entityId 主体id
   */
  getPlatformList(entityId: string) {
    return http.request('get', `/entity/${entityId}/platform`)
  },
  /**
   * 导出 Excel数据
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.type 导出类型
   * @property {object} data.extra 额外参数
   */
  exportExcelData(entityId: string, data: object) {
    return http.request('post', `/entity/${entityId}/export/excel`, { data })
  },
  /**
   * 设置损益计算时间点
   * @param {string} entityId 主体Id
   * @param {object} data 请求参数
   * @property {string} data.checkpoint 时间点
   */
  setCheckpoint(entityId: string, data: object) {
    return http.request('post', `/entity/${entityId}/changeCheckpoint`, { data })
  },
  /**
   * 获取导出记录列表
   * @param {string} entityId 主体id
   * @param {object} params 请求参数
   * @property {number} params.type 导出类型
   */
  getExportTaskHistoryList(entityId: string, params: object) {
    return http.request<ResponseData<ExportTaskHistoryItemType[]>>('get', `/entity/${entityId}/export/task`, { params })
  },

  /**
   * 删除单条导出记录
   * @param {string} entityId 主体id
   * @param {string} exportTaskId 导出任务Id
   */
  deleteExportTaskHistory(entityId: string, exportTaskId: string) {
    return http.request('delete', `/entity/${entityId}/export/task/${exportTaskId}`)
  },
  /**
   * 设置余额校验宽容度
   * @param {string} entityId 主体id
   * @param {object} data 请求参数
   * @property {object} data.treasuryVerifyTolerance 余额校验宽容度
   * @property {number} [data.treasuryVerifyTolerance.difference] 原始币种差值
   * @property {number} [data.treasuryVerifyTolerance.differenceValue] 差值报告币种金额
   */
  setTreasuryBalanceVerifyTolerance(entityId: string, data: object) {
    return http.request('post', `/entity/${entityId}/treasuryBalance/verify/tolerance`, { data })
  }
}

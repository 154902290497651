<template>
  <el-dialog
    v-model="showMessageBoxDialog"
    width="30%"
    :show-close="false"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-confirm-info-dialog"
    :class="props.class"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-confirm-info-header__title">{{ props.title }}</h4>
      <SvgIcon
        v-if="props.showCloseButton"
        name="common-close"
        width="24"
        height="24"
        class="elv-confirm-info-header__close"
        @click="onCheckMessageBoxDialog"
      />
    </template>
    <slot name="content"></slot>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          v-if="props.showCancelButton"
          type="info"
          round
          class="elv-confirm-info-footer__cancel"
          @click="onCancel"
          >{{ props.cancelButtonText }}</el-button
        >
        <el-button
          type="primary"
          round
          class="elv-confirm-info-footer__confirm"
          :class="{ 'elv-confirm-info-footer__confirm-is-disabled': isDisabledConfirmButton }"
          :loading="props.loading"
          @click="onConfirm"
          >{{ props.confirmButtonText }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { $t } from '@/i18n'

type ClassType = string | Record<string, boolean> | (string | Record<string, boolean> | ClassType)[]

const props = defineProps({
  showCancelButton: {
    type: Boolean,
    default: true
  },
  showCloseButton: {
    type: Boolean,
    default: false
  },
  cancelButtonText: {
    type: String,
    default: $t('button.cancel')
  },
  confirmButtonText: {
    type: String,
    default: $t('button.confirm')
  },
  loading: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    required: true
  },
  class: {
    type: [String, Object, Array] as PropType<ClassType>,
    default: ''
  },
  isDisabledConfirmStatus: {
    type: Boolean,
    default: false
  }
})

const showMessageBoxDialog = ref(false)

const emit = defineEmits(['onConfirmEvent', 'onCancelEvent'])

const isDisabledConfirmButton = computed(() => {
  return props.isDisabledConfirmStatus
})

// 切换弹窗展示
const onCheckMessageBoxDialog = () => {
  showMessageBoxDialog.value = !showMessageBoxDialog.value
}

const onConfirm = () => {
  if (isDisabledConfirmButton.value) return
  emit('onConfirmEvent')
}

const onCancel = () => {
  emit('onCancelEvent')
}

defineExpose({ onCheckMessageBoxDialog })
</script>

<style lang="scss">
.elv-confirm-info-dialog {
  width: 480px;
  height: auto;
  border-radius: 6px;
  box-shadow:
    0 2px 6px rgb(0 0 0 / 5%),
    0 0 1px rgb(0 0 0 / 30%);

  .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 45px;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #1e2024;
  }

  .el-dialog__header {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-top: 26px;
    // height: 49px;
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    color: #0e0f11;
    text-align: center;
    border-bottom: 0;

    .elv-confirm-info-header__title {
      display: flex;
      align-items: center;
      margin: 0;
      font-family: 'Plus Jakarta Sans';
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      color: #0e0f11;
      word-break: break-word;
    }

    .elv-confirm-info-header__close {
      position: absolute;
      right: 26px;
      bottom: 3px;
      cursor: pointer;
    }
  }

  .elv-confirm-content-info__title {
    display: flex;
    align-items: center;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #1e2024;
    text-align: center;
    word-break: break-word;
  }

  .el-dialog__footer {
    padding-top: 0;
    padding-bottom: 20px;
    text-align: center;

    .el-button {
      width: 117px;
      height: 44px;
      padding: 14px 35px;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      color: #fff;
      background: #1753eb;
      border: 0;
      border-radius: 22px;
    }

    .elv-confirm-info-footer__cancel {
      margin-right: 20px;
      color: #636b75;
      background: #edf0f3;
    }

    .elv-confirm-info-footer__confirm-is-disabled {
      color: #636b75;
      background: #edf0f3;
    }
  }
}
</style>

import { http } from '@/lib/http'
import { CurrencyItemType } from '#/ReportsTypes'
import { ConfigType, ProtocolListType } from '#/GlobalConfigTypes'

export default {
  /**
   * 获取配置
   */
  getGlobalConfig: () => {
    return http.request<ResponseData<ConfigType>>('get', `/config`)
  },
  /**
   * 可搜索的Currency列表
   * @param {Object} params
   * @param {string|number} params.entityId 项目id
   * @param {boolean} params.recommend 是否推荐
   * @param {string[]} [params.keywords] symbol关键字
   * @param {boolean} [params.isPriceProvider] 是否返回自定义Asset含有价格源的币种
   * @param {string} [params.type] CRYPTO/FIAT 币种类型
   * @param {string} [params.name] 币种名称搜索
   */
  searchCurrencyList: (params: object) => {
    return http.request<ResponseData<CurrencyItemType[]>>('get', `/underlyingCurrency`, { params })
  },
  /**
   * 获取协议列表
   * @param {Object} params
   * @param {number} params.page 页码
   * @param {number} params.limit 每页数量
   * @param {string} [params.keyword] 关键字
   */
  getProtocolList: (params: object) => {
    return http.request<ResponseData<ProtocolListType>>('get', `/defiHoldingProtocol`, { params })
  }
}
